import { createContext, useCallback, useContext, useState } from 'react'

export const FeatureFlagContext = createContext({})
export const FeatureFlagProvider = ({ children }) => {
  const [variations, _setVariations] = useState({})

  const setVariation = (experimentSection, variation) => {
    _setVariations((state) => ({ ...state, [experimentSection]: variation }))
  }

  const setVariations = (variations) => {
    _setVariations((state) => ({
      ...state,
      ...variations.reduce(
        (result, { experimentSection, variation }) => ({
          ...result,
          [experimentSection]: variation,
        }),
        {}
      ),
    }))
  }

  const getExperimentVariation = (
    experimentSection,
    defaultValue = 'control'
  ) => {
    return variations[experimentSection] || defaultValue
  }

  return (
    <FeatureFlagContext.Provider
      value={{
        setVariation,
        setVariations,
        getExperimentVariation,
        variations,
      }}
    >
      {children}
    </FeatureFlagContext.Provider>
  )
}
export const useFeatureFlags = () => useContext(FeatureFlagContext)

export const ABTestingContext = createContext({})
export const useABTestingVersion = () => useContext(ABTestingContext)
