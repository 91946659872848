import { APP_BASE_PATH } from './index'

export const TITLE = 'FREEDOM DEBT RELIEF (Credit Card Debt Relief Program)'
export const DESCRIPTION =
  'Freedom Debt Relief is America’s leader in credit card debt relief and debt consolidation to help clients lower their monthly payments without bankruptcy or loans.'
export const URL = APP_BASE_PATH()

export default {
  title: TITLE,
  description: DESCRIPTION,
  canonical: URL,
  openGraph: {
    title: TITLE,
    description: DESCRIPTION,
    url: URL,
    images: [
      {
        url: 'https://freedomdebtrelief.com/next-assets/alejandra_hero_1599x531.webp',
        width: 800,
        height: 600,
        alt: 'Freedom Debt Relief Client',
      },
    ],
    site_name: 'Freedom Debt Relief',
    type: 'article',
    article: {
      authors: ['https://www.facebook.com/freedomdebtrelief'],
    },
  },
}
