import getConfig from 'next/config'

export const APP_BASE_PATH = () =>
  getConfig().publicRuntimeConfig?.APP_BASE_PATH ||
  'https://www.freedomdebtrelief.com'

export const APPLY_FDR_URL = () =>
  getConfig().publicRuntimeConfig?.APPLY_FDR_URL ||
  'https://apply.freedomdebtrelief.com'

export const DASHBOARD_FDR_URL = () =>
  getConfig().publicRuntimeConfig?.DASHBOARD_FDR_URL ||
  'https://fdr.programdashboard.com'

export const LX_FDR_URL = () =>
  getConfig().publicRuntimeConfig?.LX_FDR_URL ||
  'https://www.freedomdebtrelief.com'

export const APPLY_ESP_FDR_URL = () => `${APPLY_FDR_URL()}/espanol`

export const TRUSTPILOT_URL =
  'https://www.trustpilot.com/review/freedomdebtrelief.com'
export const TRUSTPILOT_TEMPLATE_ID = '539adbd6dec7e10e686debee'
export const TRUSTPILOT_BUSINESS_ID = '519376ed00006400052d1a3a'

export const RESOLVE_DEBT_DATA = {
  title: 'Resolve Your Debt Now!',
  description: 'Find out how our program could help.',
  options: [
    'One low monthly program payment',
    'Settlements for less than owed',
    'Debt could be resolved in 24-48 months*',
  ],
  buttonTitle: 'Get Your Free Evaluation',
  buttonUrl: APPLY_FDR_URL(),
}

export const CONTENT_TYPES = {
  SIMPLE_ARTICLE_PAGES: 'wwwSimpleArticlePages',
  DISCLAIMER_PAGE_LAYOUT: 'wwwDisclaimerPages',
  SECTIONS_WITH_BG: 'wwwSectionsWithBg',
  ARTICLE_PAGES: 'wwwArticlePages',
  BLOG_PAGES: 'wwwBlogPosts',
  DOCUMENT_PAGES: 'wwwDocumentPages',
  TABLES: 'wwwTables',
  IFRAMES: 'wwwIFrames',
  IFRAMES_PAGES: 'wwwIFramePages',
  DEBT_CONSOLIDATION_STATES_CITIES: 'wwwDebtConsolidationStatesCities',
  DEBT_CONSOLIDATION_CARDS: 'wwwDebtConsolidationCards',
  CLIENT_STORIES: 'wwwClientStories',
  GALLERY: 'wwwGallery',
  FLOATED_IMAGE_TEXT: 'wwwFloatedImageText',
  NEW_TABLE: 'wwwNewTable',
  NEW_PROS_AND_CONS: 'wwwNewProsAndCons',
}
