import React, {
  createContext,
  useContext,
  useCallback,
  useState,
  useRef,
  useEffect,
} from 'react'
import { getPageName, getTealiumEnvPath, getTitleFromPath } from './helpers'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { trackEvent, trackView } from './utils'
import { useBot } from '../hooks/useBot'
import dynamic from 'next/dynamic'
import { getCookies } from '../helpers'

const DatadogRum = dynamic(() => import('../../components/shared/DatadogRum'))

export const AnalyticsContext = createContext({})

export const useAnalyticsProvider = ({
  utm_source,
  event_origin,
  isCrawler,
}) => {
  const router = useRouter()
  const firstCall = useRef(false)

  useEffect(() => {
    router.beforePopState(({ as }) => {
      if (as !== router.asPath) {
        firstCall.current = false
      }

      return true
    })

    return () => {
      router.beforePopState(() => true)
    }
  }, [router])

  const { asPath } = useRouter()
  const [loaded, setLoaded] = useState(false)
  const page_name = getPageName(asPath)
  const trackingDataRef = useRef()
  const scrollListener = useRef()
  const isBot = useBot()

  let pathname, href, hostname, currentEnv, urlData, pageTitle, script_path

  if (typeof window !== 'undefined') {
    href = window.location.href
    pathname =
      window.location.pathname?.length > 1
        ? window.location.pathname?.slice(1, -1).split('/').join(': ')
        : 'home'
    hostname = window.location.hostname
    //TODO remove
    currentEnv = getTealiumEnvPath(hostname).currentEnv
    script_path = getTealiumEnvPath(hostname).script_path
    urlData = {
      path: window.location.href?.split('?')[0] ?? '',
      host: hostname ?? '',
      urlParams: window.location.href?.split('?')[1]
        ? '?' + window.location.href?.split('?')[1]
        : '',
    }
    pageTitle = getTitleFromPath(urlData.path)
  }

  const onLoad = useCallback(() => {
    if (!loaded) setLoaded(true)
  }, [loaded])

  const view = useCallback(
    (statusCode, dataVisualization, experimentSection, variations = []) => {
      trackingDataRef.current = { variations }
      trackView({
        pageTitle,
        currentEnv,
        urlData,
        pathname,
        href,
        page_name,
        referringUrl: document.referrer,
        statusCode,
        isCrawler,
        dataVisualization,
        experimentSection,
        variations,
      })
    },
    [currentEnv, href, pageTitle, pathname, urlData, page_name, isCrawler]
  )

  const track = useCallback(
    (tealiumData, experimentData, action, statusCode) => {
      const { appendCookieVariations = false } = tealiumData
      const { experiments = [] } = getCookies()
      const defaultVariations = trackingDataRef.current?.variations || []

      trackEvent({
        tealiumData,
        experimentData,
        action,
        pageTitle,
        currentEnv,
        urlData,
        pathname,
        href,
        page_name,
        referringUrl: document.referrer,
        statusCode,
        isCrawler,
        variations: appendCookieVariations
          ? [...experiments, ...defaultVariations]
          : defaultVariations,
      })
    },
    [pageTitle, currentEnv, urlData, pathname, href, page_name, isCrawler]
  )

  return {
    track,
    view,
    utm_source,
    script_path,
    onLoad,
    loaded,
    scrollListener,
    isBot,
  }
}

export const useAnalytics = () => useContext(AnalyticsContext)

export const AnalyticsProvider = React.memo(
  ({ children, pageProps = {}, urlParams = null, isCrawler }) => {
    const props = useAnalyticsProvider({ ...pageProps, isCrawler })
    const nonce = process.env.nonce

    const addTealiumScript = (TEALIUM_UTAG_URL) => {
      return `
    window.utag_cfg_ovrd = window.utag_cfg_ovrd || {}
    window.utag_cfg_ovrd.noview = true
    ;(function (a, b, c, d) {
      a = '${TEALIUM_UTAG_URL}'
      b = document
      c = 'script'
      d = b.createElement(c)
      d.src = a
      d.type = 'text/java' + c
      d.async = true
      a = b.getElementsByTagName(c)[0]
      a.parentNode.insertBefore(d, a)
    })()
  `
    }

    return (
      <AnalyticsContext.Provider value={props}>
        <DatadogRum />
        {children}
        {props.isBot || isCrawler || urlParams?.includes('no3p') ? null : (
          <Script
            type="text/javascript"
            onLoad={props.onLoad}
            id="tealium-base"
            afterInteractive
            dangerouslySetInnerHTML={{
              __html: addTealiumScript(props.script_path),
            }}
            nonce={nonce}
            defer
          />
        )}
      </AnalyticsContext.Provider>
    )
  }
)

AnalyticsProvider.displayName = 'AnalyticsProvider'
