import { useState, useEffect } from 'react'

export const useBot = () => {
  const [isBot, setIsBot] = useState(true)

  useEffect(() => {
    const checkCrawler = async () => {
      const { isCrawler } = await import('@achieve/cx-request-checks')

      if (isCrawler(navigator.userAgent)) {
        return
      }

      setIsBot(false)
    }

    checkCrawler()
  }, [])

  return isBot
}
