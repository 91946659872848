import { parseCookies } from './utils'

export function getFullUrlFromReq(req, pid = []) {
  const forwardedHostKey = 'x-forwarded-host'
  let { host } = req.headers
  let protocol = /^localhost(:\d+)?$/.test(host) ? 'http:' : 'https:'

  if (
    req.headers[forwardedHostKey] &&
    typeof req.headers[forwardedHostKey] === 'string'
  ) {
    host = req.headers[forwardedHostKey]
  }

  const path = pid.length ? `/${pid?.join('/')}/` : '/'

  return {
    protocol,
    host,
    origin: `${protocol}//${host}`,
    canonicalUrl: `${protocol}//${host}${path}`,
  }
}

export const parseServerCookies = (context) => {
  const cookies = context.req?.headers['cookie'] || ''

  return parseCookies(cookies)
}
