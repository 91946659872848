import React, { createContext, useContext, useEffect, useRef } from 'react'
import { v4 as uuid } from 'uuid'
import { setCookie, getCookies } from '../helpers'

const LeadIdContext = createContext(null)

export const LeadIdProvider = ({ children, leadIdFromServerCookies = '' }) => {
  const leadIdFromCookies = getCookies()?.leadId
  const leadIdRef = useRef(leadIdFromCookies || leadIdFromServerCookies)

  useEffect(() => {
    let topDomain = (
      global?.window?.location.hostname?.match(/\w+/gim).slice(-2) || []
    ).join('.')

    if (topDomain) {
      topDomain = `.${topDomain}`
    }

    if (!leadIdRef.current) {
      const key = 'leadId'
      const newId = uuid()
      leadIdRef.current = newId

      setCookie(key, newId, { path: '/', expiration: null, domain: topDomain })
    }
  }, [])

  return (
    <LeadIdContext.Provider value={leadIdRef.current}>
      {children}
    </LeadIdContext.Provider>
  )
}

export const useLeadId = () => useContext(LeadIdContext)

LeadIdProvider.displayName = 'LeadIdProvider'
